import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import { GET_TOPUP } from "@/graphql/topup";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { BANK_URL, imageUrlApi, WEBSITE } from "@/config";
export default {
  name: "TopupList",
  created: function created() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "\u0E01\u0E23\u0E30\u0E40\u0E1B\u0E4B\u0E32\u0E40\u0E07\u0E34\u0E19",
      route: "/wallet?cat=2"
    }, {
      title: "\u0E40\u0E15\u0E34\u0E21\u0E40\u0E07\u0E34\u0E19"
    }]);
    document.title = "\u0E40\u0E15\u0E34\u0E21\u0E40\u0E07\u0E34\u0E19 | ".concat(WEBSITE);
  },
  data: function data() {
    var id = this.$route.params.id;
    return {
      BANK_URL: BANK_URL,
      valid: true,
      id: Number(id),
      amount: 0,
      date: new Date(),
      previewImage: null,
      file: null,
      fileValid: true,
      dateValid: true,
      bank: null
    };
  },
  methods: {
    imageUrlApi: imageUrlApi,
    format_number: function format_number(val) {
      var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      return bigDecimal.round(val, decimal);
    },
    format_number_pretty: function format_number_pretty(val) {
      var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      return bigDecimal.getPrettyValue(this.format_number(val, digits));
    },
    format_date: function format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    getStatusColor: function getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";

        case 0:
          return "warning";

        case 1:
          return "success";

        case 2:
          return "warning";

        case 3:
          return "success";

        default:
          return "primary";
      }
    }
  },
  apollo: {
    topup: {
      query: GET_TOPUP,
      variables: function variables() {
        return {
          id: this.id
        };
      }
    }
  }
};